$('.rodape .form-control').each(function() {
	var input 	= $(this);
	var label 	= input.siblings('label');
	var parent 	= input.parent();

	input.focus(function() {
		parent.addClass('focus');
	});

	input.blur(function() {
		if(input.val() == '') {
			parent.removeClass('focus');
		}
	});

	if(input.val() != '') {
		parent.addClass('focus');
	}
});
