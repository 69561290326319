$('[data-toggle="menu"]').click(function (e) {
	e.preventDefault();

	var btn = $(this);
	var bg 	= document.createElement('div');

	$(bg).addClass('menu-backdrop')
		.click(function(e) {
			e.preventDefault();

			$('.topo-content').removeClass('show');
			btn.removeClass('active');

			$(this).fadeOut(600, function() {
				$(this).remove();
			});
		});

	$('.topo-content').addClass('show');
	btn.addClass('active').blur();

	document.querySelector('.topo').appendChild(bg);
	$(bg).fadeIn(600);
});
