window.addEventListener('load', function() {
	$('.carousel-tiles').each(function() {
		var self 		= $(this);
		var items 		= self.find('.item');
		var lastItem 	= self.find('.item:last-child img');
		var jaResize 	= false;

		function draw(items) {
			self.removeClass('loaded');
			items.removeClass('carousel-item active').removeAttr('style');

			items.each(function(index, el){
				var img = $(this).find('img');
				var w 	= img.outerWidth();
				var h 	= img.outerHeight();
				var s 	= (h / w) * 100;
				var src = img.attr('src');

				$(this).find('.tiles').remove();
				$(this).css('padding-bottom', s+'%');
				$(this).append('<div class="tiles'+(index % 2 > 0 ? ' vertical' : '')+'"></div>');

				var t = $(this).find('.tiles');

				for(var i=0;i<10;i++) {
					var p = -10 * i;
					var style = 'width: 10%;'+
					'height: 100%;'+
					'background-position: '+((w/-10) * i)+'px top;'+
					'background-image: url('+src+');'+
					'left: '+(i * 10)+'%;'+
					'background-size: '+w+'px '+h+'px;'+
					'animation-name: tilesTop;';

					if(index % 2 != 0) {
						style = 'width: 100%;'+
						'height: 10%;'+
						'background-position: 0px '+((h/-10) * i)+'px;'+
						'background-image: url('+src+');'+
						'top: '+(i * 10)+'%;'+
						'left: 0;'+
						'background-size: '+w+'px '+h+'px;'+
						'animation-name: tilesLeft;';
					}

					t.append('<div class="tile" style="'+style+'"></div>')
				}

				img.hide();

				$(this).addClass('carousel-item');

				var next = $(this).next();

				next.css({
					backgroundImage : 'url('+src+')'
				});

				if(index == 0) {
					$(this).addClass('active');
					$(this).css('background-image', 'url('+lastItem.attr('src')+')')
				}
			});

			self.addClass('loaded')
		}

		draw(items);

		$(window).resize(function() {
			if(jaResize) return;

			setTimeout(function() {
				jaResize = false;
			}, 100);

			draw(items);
		});

	});

});

$('[data-slide="prev"]').click(function(e) {
	e.preventDefault();

	$($(this).attr('href')).carousel('prev')
});

$('[data-slide="next"]').click(function(e) {
	e.preventDefault();

	$($(this).attr('href')).carousel('next')
});
