$('.nav-servicos a').click(function(e) {
	e.preventDefault();
	var target = $(this).data('target');
})


$('.servicos-vitrine').on('slid.bs.carousel', function(e){
	var active = $(this).find('.active').index();

	var li = $('.nav-servicos li:nth-child('+(active+1)+')')

	$('.nav-servicos').scrollLeft(li.position().left);
});
